import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
  name: "AlumnoPage",
  data() {
    let ss = new MainService();
    return {
      msg: "AlumnoPage",
      ss: ss,
      ajax: {
        url: ss.indexAlumno(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "paterno", name: "paterno", title: "Paterno" },
        { data: "materno", name: "materno", title: "Materno" },
        { data: "nombre", name: "nombre", title: "Nombre" },
        { data: "ci", name: "ci", title: "CI" },
        { data: "motivo", name: "motivo", title: "Motivo" },
        {
          data: null, // No estamos tomando los datos de una propiedad específica
          name: "notaPromedio",
          title: "Nota Promedio",
          render: function (data, type, row) {
            // Verifica si nota_o es null o 0
            if (row.nota_o === null || Number(row.nota_o) === 0) {
              // Si es así, muestra nota_d
              return row.nota_d;
            } else {
              // Si no, calcula y muestra el total
              let total = row.nota_o * 0.25 + row.nota_d * 0.75;
              return total.toFixed(2);
            }
          },
          orderable: false,
          searchable: false,
        },

        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      alumnos: [],
      alumno: {},
      isLoading: false,
      errorBag: {},
      pdfUrl: "",
      carreras: "",
      instituciones: "",
      coordinadores: "",
      evaluadores: "",
    };
  },
  methods: {
    newAlumno() {
      this.errorBag = {};
      this.alumno = {};
      this.isLoading = true;
      this.ss
        .listAlumno()
        .then((result) => {
          let response = result.data;
          this.instituciones = response.instituciones;
          this.evaluadores = response.evaluadores;
          this.carreras = response.carreras;
          this.coordinadores = response.coordinadores;
          //console.log(response);
          this.$refs["frm-alumnonew"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    showAlumno(id) {
      this.isLoading = true;
      this.ss
        .showAlumno(id)
        .then((result) => {
          let response = result.data;
          this.alumno = response.data;
          this.instituciones = response.instituciones;
          this.evaluadores = response.evaluadores;
          this.carreras = response.carreras;
          this.coordinadores = response.coordinadores;
          this.pdfUrl =
            "http://cursosinglesbackend.servicios.emi.edu.bo/storage/documents/" + this.alumno.pdf;
          //console.log(this.pdfUrl);
          this.$refs["view-alumno"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editAlumno() {
      this.errorBag = {};
      this.$refs["frm-alumno"].show();
      this.$refs["view-alumno"].hide();
    },
    cancelAlumno() {
      if (this.alumno.id) {
        this.$refs["view-alumno"].show();
      }
      this.$refs["frm-alumno"].hide();
    },
    saveAlumno() {
      // Obtener el archivo seleccionado
      let file = document.querySelector('input[type="file"]').files[0];

      if (file) {
        // Crear un FormData para enviar el archivo al backend
        let formData = new FormData();
        formData.append("File", file);

        // Enviar el archivo al backend utilizando la función uploadFile
        this.ss
          .uploadFile(formData)
          .then((response) => {
            //console.log(response.data);
            this.alumno.pdf = response.data.data;
            //console.log(this.alumno);
            // Guardar la instancia
            this.storAlumno();
          })
          .catch((error) => {
            console.error("Error al subir el archivo:", error);
            // Aquí puedes manejar los errores, por ejemplo, mostrar un mensaje de error al usuario
          });
      } else {
        this.storAlumno();
      }
    },

    storAlumno() {
      // console.log(this.alumno);
      this.ss
        .storeAlumno(this.alumno)
        .then((result) => {
          //console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$refs["frm-alumno"].hide();
          this.$refs["frm-alumnonew"].hide();
          this.$refs["datatable-alumno"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deleteAlumno() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyAlumno(this.alumno)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "El certificado ha sido eliminado.",
                icon: "success",
              });
              this.$refs["view-alumno"].hide();
              this.$refs["datatable-alumno"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    verAlumno() {
      //console.log(this.alumno);
      this.ss
        .watchAlumno(this.alumno)
        .then((response) => {
          // Aquí puedes manejar la respuesta si es necesario
          //console.log("URL del PDF:", response.data.pdf_url);

          // Abrir el PDF en una nueva ventana del navegador
          window.open(response.data.pdf_url);
        })
        .catch((error) => {
          // Manejar errores en caso de que ocurran
          console.error("Error al generar el certificado:", error);
          // Puedes mostrar una notificación de error o realizar otra acción para manejar el error
        });
    },
    draw() {
      window.$(".btn-datatable-Alumno").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showAlumno(data);
      });
    },
  },
  components: {
    dataTable,
  },
  computed: {
    persona() {
      return JSON.parse(localStorage.getItem("persona"));
    },
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
