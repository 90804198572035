import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
  name: "CreateConvPage",
  data() {
    let ss = new MainService();
    return {
      msg: "CreateConvPage",
      ss: ss,
      ajax: {
        url: ss.indexCreateConv(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "paterno", name: "paterno", title: "Paterno" },
        { data: "materno", name: "materno", title: "Materno" },
        { data: "nombre", name: "nombre", title: "Nombre" },
        { data: "ci", name: "ci", title: "CI" },
        { data: "motivo", name: "motivo", title: "Certificado" },
        { data: "carrera.Carrera", name: "Carrera", title: "Carrera" },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      createconvs: [],
      createconv: {
        notacomp: "",
        notaoral: "",
      },
      isLoading: false,
      errorBag: {},
      gestion: null,
      notaTotal: null,
      carrera: "",
      unidadAcademica: "",
      instituciones: "",
      evaluadores: "",
      idiomas: "",
    };
  },
  methods: {
    showCreateConv(id) {
      this.isLoading = true;
      this.ss
        .showCreateConv(id)
        .then((result) => {
          let response = result.data;
          this.createconv = response.data;
          this.gestion = response.gestion;
          this.instituciones = response.instituciones;
          this.idiomas = response.idiomas;
          this.evaluadores = response.evaluadores;
          this.carrera = response.carrera;
          this.unidadAcademica = response.unidadAcademica;
          this.notaTotal = response.notaTotal;
          //console.log(response);
          this.$refs["view-createconv"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editCreateConv() {
      this.$refs["frm-createconv"].show();
      this.$refs["view-createconv"].hide();
    },
    cancelCreateConv() {
      if (this.createconv.id) {
        this.$refs["view-createconv"].show();
      }
      this.$refs["frm-createconv"].hide();
    },
    saveCreateConv() {
      let file = document.querySelector('input[type="file"]').files[0];
      let formData = new FormData();
      formData.append("File", file);
      this.ss
        .uploadFile(formData)
        .then((response) => {
          //console.log(response.data);
          this.createconv.pdf = response.data.data;
          //console.log(this.createconv);
          this.ss
            .storeCreateConv(this.createconv)
            .then((result) => {
              //console.log(result);
              let response = result.data;
              this.$bvToast.toast(response.msg, {
                title: "Correcto",
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["frm-createconv"].hide();
              this.$refs["datatable-createconv"].reload();
            })
            .catch((error) => {
              this.errorBag = error.response.data.errors;
              this.$bvToast.toast(
                "Problema al Guardar el Registro, favor verificar los Datos",
                {
                  title: "Error",
                  variant: "danger",
                  autoHideDelay: 5000,
                }
              );
            });
        })
        .catch((error) => {
          console.error("Error al subir el archivo:", error);
        });
    },
    deleteCreateConv() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyCreateConv(this.createconv)
            .then((result) => {
              let response = result.data;
              //console.log(response);
              this.$swal({
                title: "¡Eliminado!",
                text: "El archivo ha sido eliminado.",
                icon: "success",
              });
              this.$refs["view-createconv"].hide();
              this.$refs["datatable-createconv"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    createCreateConv() {
      this.errorBag = {};
      this.createconv.gestion = document.querySelector(
        'input[name="gestion"]'
      ).value;
      this.createconv.motivo_anterior = document.querySelector(
        'input[name="motivo_anterior"]'
      ).value;
      this.createconv.nota_d = document.querySelector(
        'input[name="nota_d"]'
      ).value;

      //console.log(this.createconv);
      this.ss
        .createcertCreateConv(this.createconv)
        .then((response) => {
          //console.log("URL del PDF:", response.data.pdf_url);
          window.open(response.data.pdf_url);
          this.$refs["frm-createconv"].show();
          this.$refs["view-createconv"].hide();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Crear el Formulario, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    draw() {
      window.$(".btn-datatable-CreateConv").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showCreateConv(data);
      });
    },
  },
  components: {
    dataTable,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
