var axios = require("axios");

export default class MainService {
  constructor() {
    var token = JSON.parse(localStorage.getItem("token"));
    axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
    if (token) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    } else {
      axios.defaults.headers.common = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
  }

  getToken() {
    return axios.defaults.headers.common;
  }

  login(item) {
    return axios.post("api/login", item);
  }

  uploadFile(item) {
    return axios.post("api/uploadFile", item);
  }

  loginToken365(item) {
    return axios.post("api/loginToken365", item);
  }

  indexPersona() {
    return axios.defaults.baseURL + "api/Persona/index";
  }

  showPersona(id) {
    return axios.get("api/Persona/show?id=" + id);
  }

  listPersona() {
    return axios.get("api/Persona/list");
  }

  storePersona(item) {
    return axios.post("api/Persona/store", item);
  }

  destroyPersona(item) {
    return axios.post("api/Persona/destroy", item);
  }

  changePassword(item) {
    return axios.post("api/Persona/changePassword", item);
  }

  showRol(id) {
    return axios.get("api/Rol/show?id=" + id);
  }

  indexRol() {
    return axios.defaults.baseURL + "api/Rol/index";
  }

  listRol() {
    return axios.get("api/Rol/list");
  }

  storeRol(item) {
    return axios.post("api/Rol/store", item);
  }

  destroyRol(item) {
    return axios.post("api/Rol/destroy", item);
  }

  showUnidadAcademica(id) {
    return axios.get("api/UnidadAcademica/show?id=" + id);
  }

  indexUnidadAcademica() {
    return axios.defaults.baseURL + "api/UnidadAcademica/index";
  }

  listUnidadAcademica() {
    return axios.get("api/UnidadAcademica/list");
  }

  storeUnidadAcademica(item) {
    return axios.post("api/UnidadAcademica/store", item);
  }

  destroyUnidadAcademica(item) {
    return axios.post("api/UnidadAcademica/destroy", item);
  }

  showCarrera(id) {
    return axios.get("api/Carrera/show?id=" + id);
  }

  indexCarrera(id) {
    return axios.defaults.baseURL + "api/Carrera/index?id=" + id;
  }

  listCarrera() {
    return axios.get("api/Carrera/list");
  }

  storeCarrera(item) {
    return axios.post("api/Carrera/store", item);
  }

  destroyCarrera(item) {
    return axios.post("api/Carrera/destroy", item);
  }

  showEvaluador(id) {
    return axios.get("api/Evaluador/show?id=" + id);
  }

  indexEvaluador() {
    return axios.defaults.baseURL + "api/Evaluador/index";
  }

  listEvaluador() {
    return axios.get("api/Evaluador/list");
  }

  storeEvaluador(item) {
    return axios.post("api/Evaluador/store", item);
  }

  destroyEvaluador(item) {
    return axios.post("api/Evaluador/destroy", item);
  }

  showInstitucion(id) {
    return axios.get("api/Institucion/show?id=" + id);
  }

  indexInstitucion() {
    return axios.defaults.baseURL + "api/Institucion/index";
  }

  listInstitucion() {
    return axios.get("api/Institucion/list");
  }

  storeInstitucion(item) {
    return axios.post("api/Institucion/store", item);
  }

  destroyInstitucion(item) {
    return axios.post("api/Institucion/destroy", item);
  }

  showAlumno(id) {
    return axios.get("api/Alumno/show?id=" + id);
  }

  indexAlumno() {
    return axios.defaults.baseURL + "api/Alumno/index";
  }

  listAlumno() {
    return axios.get("api/Alumno/list");
  }

  storeAlumno(item) {
    return axios.post("api/Alumno/store", item);
  }

  destroyAlumno(item) {
    return axios.post("api/Alumno/destroy", item);
  }

  watchAlumno(item) {
    return axios.post("api/Alumno/watch", item);
  }

  showCreateInst(id) {
    return axios.get("api/CreateInst/show?id=" + id);
  }

  indexCreateInst() {
    return axios.defaults.baseURL + "api/CreateInst/index";
  }
  filtradoCreateInst(ci) {
    return axios.get("api/CreateInst/filtrado?ci=" + ci);
  }
  listCreateInst() {
    return axios.get("api/CreateInst/list");
  }

  storeCreateInst(item) {
    return axios.post("api/CreateInst/store", item);
  }

  destroyCreateInst(item) {
    return axios.post("api/CreateInst/destroy", item);
  }

  createcertCreateInst(item) {
    return axios.post("api/CreateInst/createcert", item);
  }

  extractCreateInst() {
    return axios.get("api/CreateInst/extract");
  }

  showCreateDiag(id) {
    return axios.get("api/CreateDiag/show?id=" + id);
  }

  indexCreateDiag() {
    return axios.defaults.baseURL + "api/CreateDiag/index";
  }
  filtradoCreateDiag(ci) {
    return axios.get("api/CreateDiag/filtrado?ci=" + ci);
  }
  listCreateDiag() {
    return axios.get("api/CreateDiag/list");
  }

  storeCreateDiag(item) {
    return axios.post("api/CreateDiag/store", item);
  }

  destroyCreateDiag(item) {
    return axios.post("api/CreateDiag/destroy", item);
  }

  createcertCreateDiag(item) {
    return axios.post("api/CreateDiag/createcert", item);
  }

  showCreateIDiag(id) {
    return axios.get("api/CreateIDiag/show?id=" + id);
  }

  indexCreateIDiag() {
    return axios.defaults.baseURL + "api/CreateIDiag/index";
  }

  filtradoCreateIDiag(ci) {
    return axios.get("api/CreateIDiag/filtrado?ci=" + ci);
  }

  listCreateIDiag() {
    return axios.get("api/CreateIDiag/list");
  }

  storeCreateIDiag(item) {
    return axios.post("api/CreateIDiag/store", item);
  }

  destroyCreateIDiag(item) {
    return axios.post("api/CreateIDiag/destroy", item);
  }

  createcertCreateIDiag(item) {
    return axios.post("api/CreateIDiag/createcert", item);
  }

  showCreateProg(id) {
    return axios.get("api/CreateProg/show?id=" + id);
  }

  indexCreateProg() {
    return axios.defaults.baseURL + "api/CreateProg/index";
  }
  filtradoCreateProg(ci) {
    return axios.get("api/CreateProg/filtrado?ci=" + ci);
  }

  listCreateProg() {
    return axios.get("api/CreateProg/list");
  }

  storeCreateProg(item) {
    return axios.post("api/CreateProg/store", item);
  }

  destroyCreateProg(item) {
    return axios.post("api/CreateProg/destroy", item);
  }

  createcertCreateProg(item) {
    return axios.post("api/CreateProg/createcert", item);
  }

  showCreateSuple(id) {
    return axios.get("api/CreateSuple/show?id=" + id);
  }

  indexCreateSuple() {
    return axios.defaults.baseURL + "api/CreateSuple/index";
  }

  listCreateSuple() {
    return axios.get("api/CreateSuple/list");
  }

  storeCreateSuple(item) {
    return axios.post("api/CreateSuple/store", item);
  }

  destroyCreateSuple(item) {
    return axios.post("api/CreateSuple/destroy", item);
  }

  createcertCreateSuple(item) {
    return axios.post("api/CreateSuple/createcert", item);
  }
  showCreateConv(id) {
    return axios.get("api/CreateConv/show?id=" + id);
  }

  indexCreateConv() {
    return axios.defaults.baseURL + "api/CreateConv/index";
  }

  listCreateConv() {
    return axios.get("api/CreateConv/list");
  }

  storeCreateConv(item) {
    return axios.post("api/CreateConv/store", item);
  }

  destroyCreateConv(item) {
    return axios.post("api/CreateConv/destroy", item);
  }

  createcertCreateConv(item) {
    return axios.post("api/CreateConv/createcert", item);
  }

  indexNewEx() {
    return axios.defaults.baseURL + "api/Export/index";
  }

  listNewEx() {
    return axios.get("api/Export/list");
  }

  sinNewEx() {
    return axios.get("api/Export/exportfaltantes", { responseType: "blob" });
  }

  conNewEx() {
    return axios.get("api/Export/exportcertificados", { responseType: "blob" });
  }
  conPdfNew(item) {
    return axios.post("api/Export/exportpdfcertificados", item);
  }
  sinPdfNew(item) {
    return axios.post("api/Export/exportpdffaltantes", item);
  }
  showIdioma(id) {
    return axios.get("api/Idioma/show?id=" + id);
  }

  indexIdioma() {
    return axios.defaults.baseURL + "api/Idioma/index";
  }

  listIdioma() {
    return axios.get("api/Idioma/list");
  }

  storeIdioma(item) {
    return axios.post("api/Idioma/store", item);
  }

  destroyIdioma(item) {
    return axios.post("api/Idioma/destroy", item);
  }
}
