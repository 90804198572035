<template>
  <footer class="footer" style="background: #f5f5f5">
    <div class="container">
      <nav>
        <!-- <ul>
          <li>
            <a href="https://www.creative-tim.com">Creative Tim</a>
          </li>
          <li>
            <a href="https://creative-tim.com/presentation"> About Us </a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com"> Blog </a>
          </li>
          <li>
            <a href="https://www.creative-tim.com/license"> Licenses </a>
          </li>
        </ul> -->
      </nav>
      <div
        class="copyright text-center"
        style="margin-top: -10px; color: #898989"
      >
        &copy; {{ new Date().getFullYear() }}
        <a
          href="https://emi.edu.bo"
          target="_blank"
          style="color: #898989 !important"
          class="text-white"
        >
          Escuela Militar de Ingenieria
        </a>
      </div>

      <div
        class="copyright text-center"
        style="margin-top: -10px; color: #898989"
      >
        &copy; {{ new Date().getFullYear() }}
        <a
          href="https://emi.edu.bo"
          target="_blank"
          class="text-white"
          style="color: #898989 !important"
          >Direccion Nacional de Tecnologias de Informacion y Comunicacion</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
