import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import { saveAs } from "file-saver";
import axios from "axios";
// import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "NewExPage",
  data() {
    let ss = new MainService();
    return {
      msg: "NewExPage",
      ss: ss,
      ajax: {
        url: ss.indexNewEx(),
        headers: ss.getToken(),
      },
      newexs: [],
      newex: {},
      isLoading: false,
      errorBag: {},
      carreras: [],
      selectedCarrera1: null, // añade esta línea
      selectedCarrera2: null,
    };
  },
  methods: {
    CargarNewEx() {
      this.ss
        .listNewEx()
        .then((result) => {
          this.carreras = result.data.data; // almacena los datos de las carreras
          console.log(this.carreras); // imprime los datos de las carreras para verificar
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withNewEx() {
      this.ss
        .conNewEx()
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "AlumnosCertificados.xlsx");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withinNewEx() {
      this.ss
        .sinNewEx()
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "AlumnosSinCertificados.xlsx");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withPdfNewEx(selectedCarrera1) {
      console.log(selectedCarrera1);
      this.ss
        .conPdfNew(selectedCarrera1)
        .then((response) => {
          //console.log("URL del PDF:", response.data.pdf_url);
          window.open(response.data.pdf_url);
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast("Seleccione una Carrera", {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    withinPdfNewEx(selectedCarrera2) {
      console.log(selectedCarrera2);
      this.ss
        .sinPdfNew(selectedCarrera2)
        .then((response) => {
          //console.log("URL del PDF:", response.data.pdf_url);
          window.open(response.data.pdf_url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    extraerdatos() {
      this.ss
        .extractCreateInst()
        .then((response) => {
          console.log("Datos:", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    dataTable,
    // Loader
  },
  computed: {
    persona() {
      return JSON.parse(localStorage.getItem("persona"));
    },
  },
  mounted() {
    this.CargarNewEx();
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
