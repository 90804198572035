import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
  name: "IdiomaPage",
  data() {
    let ss = new MainService();
    return {
      msg: "IdiomaPage",
      ss: ss,
      ajax: {
        url: ss.indexIdioma(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Idioma", name: "Idioma", title: "Idioma" },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      idiomas: [],
      idioma: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newIdioma() {
      this.errorBag = {};
      this.idioma = {};
      this.$refs["frm-idioma"].show();
    },
    showIdioma(id) {
      this.isLoading = true;
      this.ss
        .showIdioma(id)
        .then((result) => {
          let response = result.data;
          this.idioma = response.data;
          this.$refs["view-idioma"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editIdioma() {
      this.errorBag = {};
      this.$refs["frm-idioma"].show();
      this.$refs["view-idioma"].hide();
    },
    cancelIdioma() {
      if (this.idioma.id) {
        this.$refs["view-idioma"].show();
      }
      this.$refs["frm-idioma"].hide();
    },
    saveIdioma() {
      this.ss
        .storeIdioma(this.idioma)
        .then((result) => {
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: "Correcto",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$refs["frm-idioma"].hide();
          this.$refs["datatable-idioma"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Guardar el Registro, favor verificar los Datos",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    deleteIdioma() {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar?",
        text: "Esta acción es irreversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ss
            .destroyIdioma(this.idioma)
            .then((result) => {
              let response = result.data;
              this.$swal({
                title: "¡Eliminado!",
                text: "El idioma ha sido eliminado.",
                icon: "success",
              });
              this.$refs["view-idioma"].hide();
              this.$refs["datatable-idioma"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Idioma").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showIdioma(data);
      });
    },
  },
  components: {
    dataTable,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Login");
    }
  },
};
