<template>
  <div
    class="sidebar"
    :data-color="sidebarItemColor"
    :style="sidebarStyle"
    style="background: white"
  >
    <div class="logo" style="background: white">
      <a href="#">
        <div class="logo-img text-center">
          <img :src="imgLogo" alt="" style="width: 160px" />
        </div>
      </a>
      <a href="#">
        <div class="logo-img text-center">
          <img :src="imgUsuario" alt="" style="width: 70px" />
        </div>
      </a>
      <a
        style="color: #898989 !important"
        target="_blank"
        class="simple-text logo-normal text-center"
      >
        {{ this.auth.Nombres }}
      </a>
    </div>
    <div class="sidebar-wrapper" style="background: white">
      <slot name="content"></slot>
      <md-list class="nav">
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>

<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: "Menu",
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/logofinal.png"),
    },
    imgUsuario: {
      type: String,
      default: require("@/assets/img/usuario.png"),
    },
    sidebarItemColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      auth: {
        Nombres: "",
        Persona: "",
      },
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    console.log(this.auth);
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundColor: "#ffffff",
      };
    },
  },
};
</script>

<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
</style>

