import { render, staticRenderFns } from "./EvaluadorPage.vue?vue&type=template&id=38cb4874"
import script from "./EvaluadorPage.js?vue&type=script&lang=js&external"
export * from "./EvaluadorPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports