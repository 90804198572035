<template>
  <div>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
      <notifications></notifications>

      <side-bar
        :sidebar-item-color="sidebarBackground"
        :sidebar-background-image="sidebarBackgroundImage"
        style="background: white"
        v-if="!$route.meta.sinmenu"
      >
        <sidebar-link
          to="/unidadacademica"
          v-if="showAdministrador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Unidades Académicas</p>
        </sidebar-link>

        <sidebar-link to="/idioma" v-if="showAdministrador" class="black-text">
          <md-icon style="color: black !important">language</md-icon>
          <p style="color: black">Idiomas</p>
        </sidebar-link>

        <sidebar-link
          to="/persona"
          v-if="showAdministrador || showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">person</md-icon>
          <p style="color: black">Usuarios</p>
        </sidebar-link>

        <sidebar-link to="/rol" v-if="showAdministrador" class="black-text">
          <md-icon style="color: black !important">security</md-icon>
          <p style="color: black">Roles</p>
        </sidebar-link>

        <sidebar-link to="/carrera" v-if="showCoordinador" class="black-text">
          <md-icon style="color: black !important">work</md-icon>
          <p style="color: black">Carreras</p>
        </sidebar-link>

        <sidebar-link to="/evaluador" v-if="showCoordinador" class="black-text">
          <md-icon style="color: black !important">thumb_up</md-icon>
          <p style="color: black">Evaluadores</p>
        </sidebar-link>

        <sidebar-link
          to="/institucion"
          v-if="showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">business</md-icon>
          <p style="color: black">Instituciónes</p>
        </sidebar-link>

        <div
          @click="showCertificados = !showCertificados"
          v-if="showCoordinador"
          class="black-text"
        >
          <sidebar-link to="#">
            <md-icon style="color: black !important">school</md-icon>
            <p style="color: black">Registro de certificado</p>
          </sidebar-link>
        </div>
        <sidebar-link
          to="/createinst"
          v-show="showCertificados && showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Certificado convenio</p>
        </sidebar-link>
        <sidebar-link
          to="/createidiag"
          v-show="showCertificados && showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Certificado Diag. Convenio</p>
        </sidebar-link>
        <sidebar-link
          to="/createprog"
          v-show="showCertificados && showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Certificado Programa</p>
        </sidebar-link>
        <sidebar-link
          to="/creatediag"
          v-show="showCertificados && showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Certificado Diagnostico</p>
        </sidebar-link>
        <sidebar-link
          to="/createconv"
          v-show="showCertificados && showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Planilla revalidacion</p>
        </sidebar-link>

        <sidebar-link
          to="/createsuple"
          v-show="showCertificados && showCoordinador"
          class="black-text"
        >
          <md-icon style="color: black !important">school</md-icon>
          <p style="color: black">Certificado supletorio</p>
        </sidebar-link>

        <sidebar-link
          to="/alumno"
          v-if="showCoordinador || showJefeSecre"
          class="black-text"
        >
          <md-icon style="color: black !important">assignment</md-icon>
          <p style="color: black">Certificados Emitidos</p>
        </sidebar-link>

        <sidebar-link
          to="/newex"
          v-if="showCoordinador || showJefeSecre"
          class="black-text"
        >
          <md-icon style="color: black !important">table_view</md-icon>
          <p style="color: black">Exportar</p>
        </sidebar-link>
      </side-bar>

      <div class="main-panel" v-if="!$route.meta.login">
        <top-navbar v-if="!$route.meta.sinheader"></top-navbar>

        <dashboard-content v-if="!$route.meta.login"> </dashboard-content>
        <login-content v-if="$route.meta.login"> </login-content>

        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>

      <div class="" v-if="$route.meta.login">
        <top-navbar v-if="!$route.meta.sinheader"></top-navbar>

        <login-content v-if="$route.meta.login"> </login-content>

        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import LoginContent from "./ContentLogin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    LoginContent,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: "", // No background image
      showAdministrador: false,
      showCoordinador: false,
      showJefeSecre: false,
      showCertificados: false,
    };
  },
  methods: {
    updateButtons() {
      const persona = JSON.parse(localStorage.getItem("persona"));
      this.showAdministrador = persona && persona.Rol === 1;
      this.showCoordinador = persona && persona.Rol === 2;
      this.showJefeSecre = persona && (persona.Rol === 3 || persona.Rol === 4);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.updateButtons();
    next();
  },
  created() {
    this.updateButtons();
  },
};
</script>

